import { BelongsTo, Model } from "spraypaint";

import ApplicationRecord from "shared/services/spraypaint";

import Influencer from "./Influencer";
import OutreachTopic from "./OutreachTopic";

@Model()
class InfluencersTopic extends ApplicationRecord {
  static jsonapiType = "influencers_topics";

  @BelongsTo() influencer: Influencer;

  @BelongsTo() outreachTopic: OutreachTopic;
}

export default InfluencersTopic;
