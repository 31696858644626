import { BelongsTo, Model } from "spraypaint";

import ApplicationRecord from "shared/services/spraypaint";

import Outlet from "./Outlet";
import OutreachTopic from "./OutreachTopic";

@Model()
class OutletsTopic extends ApplicationRecord {
  static jsonapiType = "outlets_topics";

  @BelongsTo() outlet: Outlet;

  @BelongsTo() outreachTopic: OutreachTopic;
}

export default OutletsTopic;
