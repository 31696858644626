import { Attr, HasMany, Model } from "spraypaint";

import ApplicationRecord from "shared/services/spraypaint";

import Influencer from "./Influencer";
import InfluencersTopic from "./InfluencersTopic";
import Outlet from "./Outlet";
import OutletsTopic from "./OutletsTopic";

@Model()
class OutreachTopic extends ApplicationRecord {
  static jsonapiType = "outreach_topics";

  @HasMany({ name: "outletTopics" }) outletTopics: OutletsTopic[];

  @HasMany() outlets: Outlet[];

  @HasMany({ name: "influencers_topics" })
  influencersTopics: InfluencersTopic[];

  @HasMany() influencers: Influencer[];

  @Attr() name: string;
}

export default OutreachTopic;
